import digital_png from './assets/landing/useCase/Pain_Demo.png';
import digital_webp from './assets/landing/useCase/Pain_Demo.webp';
import consumer_png from './assets/landing/useCase/Screenshot 2024-08-23 at 15.09.07.png';
import consumer_webp from './assets/landing/useCase/Screenshot 2024-08-23 at 15.09.07.webp';
import customerExp_png from './assets/landing/useCase/Screenshot 2024-08-23 at 15.09.47.png';
import customerExp_webp from './assets/landing/useCase/Screenshot 2024-08-23 at 15.09.47.webp';

export const pricing = [
  {
    title: 'Trial',
    subtitle: 'Explore the platform for FREE - no credit card required',
    feature: [
      '5 tokens',
      'Downscale Videos to SD',
      '1 Project',
      '5 Minute Max Video Duration',
      'Email Support',
    ],
    link: '',
    priceMonthly: 'Free',
    priceYearly: 'Free',
    cta: 'Start Free 14 Day Trial',
    slug: 'trial',
    monthlyValue: null,
    yearlyValue: null,
    seats: 1,
  },
  {
    title: 'Basic',
    subtitle: '',
    feature: [
      '10 tokens',
      'Downscale Videos to SD',
      '3 Project',
      '5 Minute Max Video Duration',
      'Email Support',
    ],
    link: 'pricing?tier=basic',
    priceMonthly: '£90',
    priceYearly: '£76.50',
    cta: 'Get Basic',
    slug: 'basic',
    monthlyValue: 90,
    yearlyValue: 76.5,
    seats: 1,
  },

  {
    title: 'Professional',
    subtitle: '',
    feature: [
      '30 tokens',
      'HD Video Upload & Processing',
      'Unlimited Projects',
      '5 Minute Max Video Duration',
      'Email Support',
    ],
    link: 'pricing?tier=pro',
    priceMonthly: '£425',
    priceYearly: '£362',
    cta: 'Get Pro',
    slug: 'pro',
    monthlyValue: 425,
    yearlyValue: 362,
    seats: 5,
  },
  {
    title: 'Enterprise',
    subtitle: '',
    feature: [
      'Token quantity negotiable',
      'HD Video Upload & Processing',
      'Unlimited Projects',
      '1 Hour Max Video Duration',
      'Designated Account Management & Support',
    ],
    link: 'mailto:enquire@blueskeye.com',
    priceMonthly: 'Price on Application',
    priceYearly: 'Price on Application',
    cta: 'Get Enterprise',
    slug: 'enterprise',
    monthlyValue: null,
    yearlyValue: null,
  },
];

export const useCases = [
  {
    id: 'digital',
    png: digital_png,
    webp: digital_webp,
    body: `Imagine Alex, an app developer working on a new mobile shopping app. His goal is to create a seamless, intuitive experience that will make users want to come back again and again. But as with all complex projects, it's hard to predict how users will feel when interacting with different parts of the app: until it's too late.

    The app testers are emulating the persona of an on-line shopper, Sarah. 

    Sarah downloads the app to shop for a gift. She starts browsing, but the app's layout is cluttered, the checkout process is slow, and she finds the payment form confusing. Frustrated, Sarah closes the app, abandoning her purchase.

    What if Alex could see all of this unfolding in real time by using our SaaS platform?

    Alex wishes to track and analyse Sarah's emotional journey; he can now use data from her expressed emotions to augment the data he already collects about clicks, swipes, or time spent in certain areas of the app. Alex gains unique and valuable insights into where Sarah's experience starts to break down. He could pinpoint her frustration when the app takes too long to load, her confusion when filling out a complicated payment form, and even her initial excitement when she found the perfect product.

    With this data, Alex and his team can make targeted improvements to the app. They could streamline the checkout flow, simplify the form fields, and reduce load times, transforming Sarah's future experience into a much smoother, more pleasant one. Instead of abandoning the purchase.

    This platform provides Alex with extra data to bridge the gap between functionality and emotion, helping him build an app that's not only efficient but emotionally positive for users. This tool empowers app developers like Alex to transform user frustrations into moments of satisfaction, driving higher engagement and retention.`,
  },
  {
    id: 'customerExp',
    png: customerExp_png,
    webp: customerExp_webp,
    body: `Imagine David, a new consultant leading virtual consultation sessions with clients. Initially, he feels hesitant, unsure if this format will work for him. As the session begins, he asks his client probing questions, and the client provides helpful, descriptive answers.
    
    However, midway through, a question triggers friction in the dialogue, and despite David's reassurance, the client becomes disengaged, offering only short responses.
    
    Now picture a platform that could overlay the client's emotional transitions on to a video of the session. Our SaaS platform analyses the client's facial expressions detecting when he felt anxious or comforted. This provides the David with objective data to augment the data collected by their own observations during the session and any post session survey. Armed with this feedback David can further develop his rapport building and empathy skills further and become more successful.
    
    The same platform could inform sales representatives as they endeavour to improve their rapport with clients. The salesperson can review the recording of their sales engagement and analyse how the customer felt: whether they were engaged, uncertain, or excited about a product. These insights allow the salesperson to adjust their approach, offering personalised responses that resonate better with the customer.
    
    By analysing emotional responses during these personal engagements, the platform helps professionals fine-tune their interactions, leading to more meaningful connections and successful outcomes in virtual consultations.`,
  },
  {
    id: 'consumer',
    png: consumer_png,
    webp: consumer_webp,
    body: `Fatima is a consumer market tester, tasked with gathering feedback on a new sparkling beverage. Traditionally, Fatima would rely on surveys and focus groups, but these methods often miss subtle, real-time emotional responses. OurSaaS platform provides a new opportunity to glean new, previously unobtainable metrics that quantify expressed emotion : a game changer for understanding how consumers truly feel while experiencing a product.
    
    Fatima invites a group of participants to taste the beverage. As they sip, the platform captures their emotional reactions using video analysis that tracks facial expressions. One participant's initial curiosity turns into surprise when the drink's bubbles are more intense than expected. Another person starts with a smile but subtly frowns as an unexpected aftertaste lingers. These nuanced emotional responses are captured in real time.
    
    Post-session, Fatima can augment the data she obtains from surveys with deep insights into how participants felt during each moment of the tasting experience.This allows Fatima to pinpoint exact aspects of the product that evoke positive or negative emotions, helping the product team adjust flavour, carbonation levels, or packaging.
    
    By using this platform, Fatima gains an edge, transforming raw feedback into rich emotional data that drives product innovation and ultimately leads to a more satisfying consumer experience.`,
  },
];
